import videojs from "video.js";
import "./third-party-library-to-import/@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm.js";
import "videojs-offset";
import "./rewind-and-forward";
import "./stepRewind-and-stepForward";
import "@videojs/http-streaming";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";

class gameVideoJsClass {
  constructor(selectorElement, options = {}) {
    this.selectorElement = selectorElement;

    if (!(this.selectorElement instanceof HTMLElement)) {
      this.selectorElement = document.querySelector(this.selectorElement);
      if (this.selectorElement.dataset) {
        options = Object.assign(this.selectorElement.dataset, options);
      }
    }

    //Default options settings
    this.options = Object.assign(
      {
        controls: true,
        autoplay: false,
        preload: "auto",
        fluid: true,
        plugins: {
          httpSourceSelector: {
            default: "auto",
          },
        },
      },
      options,
    );

    this.player = false;

    this.defaultDuration = false;

    //Options crop
    this.startCrop = this.options.startCrop
      ? parseFloat(this.options.startCrop)
      : false;
    this.endCrop = this.options.endCrop
      ? parseFloat(this.options.endCrop)
      : false;
    this.cropMatch = this.options.cropMatch == "true" ? true : false;

    //Default options
    this.manualTime = this.options.manualTime ? true : false;
    //Total time of match time  //Default 45min
    this.gameMatchTime = parseFloat(
      this.options.gameMatchTime ? this.options.gameMatchTime : 2700,
    );
    //Set start first match time
    this.firstMatchStartTime = this.options.firstMatchStartTime
      ? parseFloat(
          this.options.firstMatchStartTime
            ? this.options.firstMatchStartTime
            : 0,
        )
      : false;
    //Set end first match time
    this.firstMatchEndTime = parseFloat(
      this.options.firstMatchEndTime
        ? this.options.firstMatchEndTime
        : this.firstMatchStartTime + this.gameMatchTime,
    );

    //Set start second match time
    this.secondMatchStartTime = parseFloat(
      this.options.secondMatchStartTime ? this.options.secondMatchStartTime : 0,
    );
    //Set end second match time
    this.secondMatchEndTime = parseFloat(
      this.options.secondMatchEndTime
        ? this.options.secondMatchEndTime
        : this.secondMatchStartTime + this.gameMatchTime,
    );

    this.additionalFirstMatchStartTime = parseFloat(
      this.options.additionalFirstMatchStartTime
        ? this.options.additionalFirstMatchStartTime
        : 0,
    );
    this.additionalFirstMatchEndTime = parseFloat(
      this.options.additionalFirstMatchEndTime
        ? this.options.additionalFirstMatchEndTime
        : 0,
    );
    this.additionalSecondMatchStartTime = parseFloat(
      this.options.additionalSecondMatchStartTime
        ? this.options.additionalSecondMatchStartTime
        : 0,
    );
    this.additionalSecondMatchEndTime = parseFloat(
      this.options.additionalSecondMatchEndTime
        ? this.options.additionalSecondMatchEndTime
        : 0,
    );

    //======================================================
    //        EXTRA TIMES
    //======================================================
    
    //Set start first match time
    this.gameMatchExtraTime = parseFloat((this.options.gameMatchExtraTime ? this.options.gameMatchExtraTime : 900));
    this.extraTimeFirstMatchStartTime = this.options.extraTimeFirstMatchStartTime ? parseFloat((this.options.extraTimeFirstMatchStartTime ? this.options.extraTimeFirstMatchStartTime : 0)) : false;
    this.extraTimeFirstMatchEndTime = parseFloat((this.options.extraTimeFirstMatchEndTime ? this.options.extraTimeFirstMatchEndTime : (this.extraTimeFirstMatchStartTime + this.gameMatchExtraTime)));
    
    this.extraTimeSecondMatchStartTime = this.options.extraTimeSecondMatchStartTime ? parseFloat((this.options.extraTimeSecondMatchStartTime ? this.options.extraTimeSecondMatchStartTime : 0)) : false;
    this.extraTimeSecondMatchEndTime = parseFloat((this.options.extraTimeSecondMatchEndTime ? this.options.extraTimeSecondMatchEndTime : (this.extraTimeSecondMatchStartTime + this.gameMatchExtraTime)));


    //======================================================
    //        Define end times default
    //======================================================

    //adicionando final do primeiro tempo quando não informado
    if (
      (this.additionalFirstMatchStartTime &&
        this.options.firstMatchEndTime == undefined) ||
      (this.additionalFirstMatchStartTime &&
        parseFloat(this.options.firstMatchEndTime) >
          this.additionalFirstMatchStartTime &&
        this.additionalFirstMatchStartTime !== 0)
    ) {
      this.firstMatchEndTime = this.additionalFirstMatchStartTime;
    } else if (this.options.firstMatchEndTime == undefined) {
      this.firstMatchEndTime = this.firstMatchStartTime + this.gameMatchTime;
    }

    //first additional time default
    if (!this.additionalFirstMatchStartTime) {
      if (this.firstMatchEndTime > 0) {
        this.additionalFirstMatchStartTime = this.firstMatchEndTime;
      }
    }

    //second additional time end
    if (this.additionalSecondMatchStartTime) {
      this.secondMatchEndTime = this.additionalSecondMatchStartTime;
    } else {
      if (this.secondMatchStartTime > 0) {
        this.additionalSecondMatchStartTime = this.secondMatchEndTime;
      }
    }

    //Interval Match Time
    this.intervalMatchStartTime = parseFloat(
      this.options.intervalMatchStartTime
        ? this.options.intervalMatchStartTime
        : 0,
    ); //First Match
    this.intervalMatchEndTime = parseFloat(
      this.options.intervalMatchEndTime ? this.options.intervalMatchEndTime : 0,
    ); //Second Match

    //Total game time
    //Multiply the value of the first game by 2
    this.totalTimeGame = this.gameMatchTime * 2;

    //Total video time in seconds
    this.totalVideoDuration = 0;

    //Text interval
    this.textInterval = this.options.textInterval
      ? this.options.textInterval
      : "--:--";

    //elements
    this.currentTimeFinalElement = false;
    this.currentTimeElement = false;
    this.cropMatchLoaded = false;
  }

  setTotalDurationHTML() {
    if (!this.defaultDuration) this.defaultDuration = this.player.duration();
    this.totalVideoDuration = this.player.duration();

    if (this.startCrop !== false) {
      this.currentTimeFinalElement.innerHTML = this.returnFormattedDataMatch(
        this.totalVideoDuration,
      );
    } else {
      this.currentTimeFinalElement.innerHTML = this.returnFormattedDataMatch(
        this.totalVideoDuration,
      );
    }
  }

  /**
   * Return real time of video.
   * Unlike currentTime, this method returns the video time in real time even if crop is set
   */
  getCurrentRealTime(byTime = false) {
    if (byTime) {
      if (this.startCrop) {
        byTime -= this.startCrop;
      } else if (
        this.cropMatch &&
        parseFloat(this.options.firstMatchStartTime)
      ) {
        byTime -= parseFloat(this.options.firstMatchStartTime);
      }

      return byTime < 0 ? 0 : byTime;
    } else {
      let time = this.player.currentTime() > 0 ? this.player.currentTime() : 0;

      if (this.startCrop) {
        time = this.startCrop + time;
        // if (this.cropMatch && parseFloat(this.options.firstMatchStartTime)) {
        //   time = time - parseFloat(this.options.firstMatchStartTime);
        // }
      } else if (
        this.cropMatch &&
        parseFloat(this.options.firstMatchStartTime)
      ) {
        time = parseFloat(this.options.firstMatchStartTime) + time;
      }

      return time;
    }
  }

  /**
   * Limit min value int
   * @param {Int} value
   * @param {Int} min
   */
  limitMin(value, min = 0) {
    return parseFloat(value) <= min ? min : parseFloat(value);
  }

  /**
   * Return start time of game
   */
  getStartTimeGame() {
    return this.firstMatchStartTime ? this.firstMatchStartTime : 0;
  }

  /**
   * Return end time of game
   */
  getEndTimeGame() {
    let endTimeGame = 0;
    if (endTimeGame < this.firstMatchEndTime)
      endTimeGame = this.firstMatchEndTime;
    if (endTimeGame < this.additionalFirstMatchEndTime)
      endTimeGame = this.additionalFirstMatchEndTime;
    if (endTimeGame < this.intervalMatchEndTime)
      endTimeGame = this.intervalMatchEndTime;
    if (endTimeGame < this.secondMatchEndTime)
      endTimeGame = this.secondMatchEndTime;
    if (endTimeGame < this.additionalSecondMatchEndTime)
      endTimeGame = this.additionalSecondMatchEndTime;

    if (
      this.options.additionalSecondMatchEndTime == undefined &&
      this.additionalSecondMatchStartTime > 0
    ) {
      endTimeGame = 0;
    }
    if (endTimeGame == 0 && this.defaultDuration !== false)
      endTimeGame = this.defaultDuration;
    return endTimeGame;
  }

  /**
   * This method will check if part-based crop is enabled
   * and will update the match time values by removing the time difference
   * from the beginning to start the game
   */
  recalculateMatchByCrop() {
    if (this.firstMatchStartTime) {
      let discountTime = this.firstMatchStartTime;
      this.firstMatchStartTime = this.limitMin(
        this.firstMatchStartTime - discountTime,
      );
      this.firstMatchEndTime = this.limitMin(
        this.firstMatchEndTime - discountTime,
      );
      this.secondMatchStartTime = this.limitMin(
        this.secondMatchStartTime - discountTime,
      );
      this.secondMatchEndTime = this.limitMin(
        this.secondMatchEndTime - discountTime,
      );
      this.additionalFirstMatchStartTime = this.limitMin(
        this.additionalFirstMatchStartTime - discountTime,
      );
      this.additionalFirstMatchEndTime = this.limitMin(
        this.additionalFirstMatchEndTime - discountTime,
      );
      this.additionalSecondMatchStartTime = this.limitMin(
        this.additionalSecondMatchStartTime - discountTime,
      );
      this.additionalSecondMatchEndTime = this.limitMin(
        this.additionalSecondMatchEndTime - discountTime,
      );
      this.intervalMatchStartTime = this.limitMin(
        this.intervalMatchStartTime - discountTime,
      );
      this.intervalMatchEndTime = this.limitMin(
        this.intervalMatchEndTime - discountTime,
      );

      /**
       * ===========================================================
       *    Extra Time
       * ===========================================================
       */
      this.extraTimeFirstMatchStartTime = this.limitMin(this.extraTimeFirstMatchStartTime - discountTime);
      this.extraTimeFirstMatchEndTime = this.limitMin(this.extraTimeFirstMatchEndTime - discountTime);

      this.extraTimeSecondMatchStartTime = this.limitMin(this.extraTimeSecondMatchStartTime - discountTime);
      this.extraTimeSecondMatchEndTime = this.limitMin(this.extraTimeSecondMatchEndTime - discountTime);
    }
  }

  setCropMatch() {
    let startTime = this.getStartTimeGame();
    let endTime = this.getEndTimeGame();

    this.recalculateMatchByCrop();
    if (this.startCrop === false) {
      this.setCrop(startTime, endTime);
    }
    this.cropMatchLoaded = true;
  }

  /**
   * Force crop in video
   * @param {Int} start - Minutes in seconds
   * @param {Int} end - Minutes in seconds
   */
  setCrop(start, end) {
    if (start !== false || end !== false) {
      let setCrop = {};

      if (start !== false) setCrop.start = start;
      if (end !== false && end > 0 && end) setCrop.end = end;

      this.player.offset(setCrop);
    }
  }

  run() {
    this.player = videojs(this.selectorElement, this.options);
    this.player.httpSourceSelector();

    this.player.gamevideojs = this;

    this.player.rewindForwardButton({
      forward: 0.05,
      back: 0.05,
    });

    this.player.stepRewindForwardButton({
      forward: 1,
      back: 1,
    });

    try {
      const seekBarPlayProgressBarTimeTooltip = this.player
        .getChild("ControlBar")
        .getChild("ProgressControl")
        .seekBar.getChild("PlayProgressBar")
        .getChild("TimeTooltip").update;

      this.player
        .getChild("ControlBar")
        .getChild("ProgressControl")
        .seekBar.getChild("PlayProgressBar")
        .getChild("TimeTooltip").update = function (e, a, b) {
        //if (!this.player_.gamevideojs.startCrop) {
        b = this.player_.gamevideojs.returnFormattedDataMatch(
          this.player_.gamevideojs.convertHourToSeconds(b),
        );
        //}
        seekBarPlayProgressBarTimeTooltip(e, a, b);
      };

      const saveSeekBarMouseTimeDisplayUpdate = this.player
        .getChild("ControlBar")
        .getChild("ProgressControl")
        .seekBar.getChild("MouseTimeDisplay")
        .getChild("TimeTooltip").update;
      this.player
        .getChild("ControlBar")
        .getChild("ProgressControl")
        .seekBar.getChild("MouseTimeDisplay")
        .getChild("TimeTooltip").update = function (e, a, b) {
        //if (!this.player_.gamevideojs.startCrop) {
        b = this.player_.gamevideojs.returnFormattedDataMatch(
          this.player_.gamevideojs.convertHourToSeconds(b),
        );
        //}
        saveSeekBarMouseTimeDisplayUpdate(e, a, b);
      };
    } catch (error) {}

    //case set crop
    if (this.startCrop !== false && this.endCrop !== false) {
      if (this.cropMatch !== false && this.firstMatchStartTime) {
        this.setCrop(
          this.startCrop + this.firstMatchStartTime,
          this.endCrop + this.firstMatchStartTime,
        );
      } else {
        this.setCrop(this.startCrop, this.endCrop);
      }
    }

    this.customizeLayoutVideojs();

    if (this.player.src().indexOf("vimeo.com") !== -1) {
      this.player.el().classList.add("video-js-vimeo");
    }

    this.player.on("loadeddata", function (e) {
      this.player_.gamevideojs.setTotalDurationHTML();

      //crop video by match
      if (
        this.player_.gamevideojs.cropMatch &&
        this.player_.gamevideojs.cropMatchLoaded == false
      ) {
        this.player_.gamevideojs.setCropMatch();
      }
    });

    this.player.on("play", function (e) {
      this.player_.gamevideojs.setTotalDurationHTML();

      if (
        this.player_.gamevideojs.endCrop &&
        this.player_.gamevideojs.getCurrentRealTime() >=
          this.player_.gamevideojs.endCrop
      ) {
        let tempThis = this.player_.el_.querySelector(".vjs-play-control");

        if (tempThis) {
          this.player_.currentTime(0);
          this.player_.play();
          tempThis.classList.remove("vjs-ended");
        }
      }
    });

    this.player.on("pause", function (e) {
      if (
        this.player_.gamevideojs.getCurrentRealTime() ==
        this.player_.gamevideojs.endCrop
      ) {
        let tempThis = this.player_.el_.querySelector(
          ".vjs-play-control.vjs-paused",
        );

        for (let indexLoop = 0; indexLoop <= 500; indexLoop++) {
          indexLoop += 50;
          setTimeout(function () {
            tempThis.classList.add("vjs-ended");
            tempThis.classList.remove("vjs-paused");
          }, indexLoop);
        }
      }
    });

    this.player.on("start", function (e) {
      this.player_.gamevideojs.setTotalDurationHTML();
    });

    this.player.on("timeupdate", function (e) {
      this.player_.gamevideojs.setTotalDurationHTML();
      this.player_.gamevideojs.currentTimeElement.innerHTML =
        this.player_.gamevideojs.returnFormattedDataMatch(this.currentTime());
    });

    this.player.gamevideojs = this;

    return this.player;
  }

  /**
   * Return real currentTime by match
   * @param {Int} time - Pass real time match
   */
  getMatchTimeByRealTime(time = false) {
    time = time == false ? this.getCurrentRealTime() : time;
    let finalTime = 0;

    if (this.startCrop !== false) {
      finalTime = time - this.startCrop;
    } else {
      finalTime = time - parseFloat(this.options.firstMatchStartTime);
    }
    return finalTime;
  }

  /**
   * Return formatted date based in time
   * @param {*} currentTimeReal
   */
  returnFormattedDataMatch(currentTimeReal) {
    let returnFormattedDataMatch = this.convertToReadTime((currentTimeReal < 0 ? 0 : currentTimeReal), 'hours');
    let currentRealGameTime = 0;
    let currentEndTimeBeforeAdditional = 0;
    let useCorrectMarkup = false;
    let firstTimeEndingTime = 0;
    let totalTimeGame = this.totalTimeGame;

    if (this.firstMatchStartTime !== false) {

      if (this.startCrop !== false) {
        currentTimeReal += this.startCrop;
        if (this.cropMatch !== false) {
          //currentTimeReal -= parseFloat(this.options.firstMatchStartTime);
        }
      }

      //first time
      if (currentTimeReal >= this.firstMatchStartTime) {
        currentRealGameTime = currentTimeReal - this.firstMatchStartTime;

        if (currentTimeReal >= this.firstMatchEndTime && this.firstMatchEndTime > this.firstMatchStartTime) {
          currentEndTimeBeforeAdditional = this.firstMatchEndTime - this.firstMatchStartTime;
          currentRealGameTime = currentEndTimeBeforeAdditional;

          if (currentTimeReal > (this.gameMatchTime + this.firstMatchStartTime) && this.firstMatchEndTime >= this.gameMatchTime) {
            currentEndTimeBeforeAdditional = this.gameMatchTime;
            currentRealGameTime = this.gameMatchTime;
          }
          //force en in totalTime match
          if (useCorrectMarkup == false) {
            currentEndTimeBeforeAdditional = this.gameMatchTime;
            currentRealGameTime = this.gameMatchTime;
          }
        } else if (currentTimeReal > (this.gameMatchTime + this.firstMatchStartTime)) {
          //limitando o tempo do jogo em 45min caso tenha setado o tempo maior que o tempo da partida
          currentEndTimeBeforeAdditional = this.gameMatchTime;
          currentRealGameTime = this.gameMatchTime;
        }

        if (useCorrectMarkup == true) {
          firstTimeEndingTime = currentRealGameTime;
        } else {
          firstTimeEndingTime = this.gameMatchTime;
        }
      }

      //aditional time first time
      if (
        currentTimeReal >= this.additionalFirstMatchStartTime &&
        this.additionalFirstMatchStartTime > this.firstMatchStartTime &&
        this.additionalFirstMatchStartTime >= this.firstMatchEndTime
      ) {
        let extraTimeMatch = currentTimeReal - this.additionalFirstMatchStartTime;
        currentRealGameTime = this.convertToReadTime(currentEndTimeBeforeAdditional) + '+' + this.convertToReadTime(extraTimeMatch);

        if (currentTimeReal >= this.additionalFirstMatchEndTime && this.additionalFirstMatchEndTime > this.additionalFirstMatchStartTime) {
          let extraTimeMatchEnd = this.additionalFirstMatchEndTime - this.additionalFirstMatchStartTime;
          currentRealGameTime = this.convertToReadTime(currentEndTimeBeforeAdditional) + '+' + this.convertToReadTime(extraTimeMatchEnd);
        }
      }

      //Interval time
      if (
        currentTimeReal >= this.intervalMatchStartTime &&
        this.intervalMatchStartTime > 0 &&
        this.intervalMatchStartTime >= this.additionalFirstMatchStartTime &&
        this.intervalMatchStartTime >= this.additionalFirstMatchEndTime &&
        this.intervalMatchStartTime >= this.firstMatchStartTime &&
        this.intervalMatchStartTime >= this.firstMatchEndTime
      ) {
        currentRealGameTime = this.textInterval;

        if (currentTimeReal >= this.intervalMatchEndTime && this.intervalMatchEndTime > this.intervalMatchStartTime) {
          currentRealGameTime = this.textInterval;
        }
      }

      //Second time
      if (
        currentTimeReal >= this.secondMatchStartTime &&
        this.secondMatchStartTime > 0 &&
        this.secondMatchStartTime >= this.additionalFirstMatchStartTime &&
        // this.secondMatchStartTime >= this.additionalFirstMatchEndTime &&
        this.secondMatchStartTime >= this.firstMatchStartTime
        // && this.secondMatchStartTime >= this.firstMatchEndTime
      ) {
        currentRealGameTime = currentTimeReal - this.secondMatchStartTime + firstTimeEndingTime;

        if (currentTimeReal >= this.secondMatchEndTime && this.secondMatchEndTime > this.secondMatchStartTime) {
          currentEndTimeBeforeAdditional = firstTimeEndingTime + this.secondMatchEndTime - this.secondMatchStartTime;
          currentRealGameTime = currentEndTimeBeforeAdditional;
        } else if (
          currentTimeReal >= this.additionalSecondMatchStartTime &&
          this.additionalSecondMatchStartTime >= this.secondMatchStartTime &&
          this.additionalSecondMatchStartTime >= this.secondMatchEndTime
        ) { //case set additional but not set end date Second time
          if (this.secondMatchEndTime > 0) {
          } else if (this.additionalSecondMatchStartTime > 0) {

            let totalTimeSecondMatch = this.additionalSecondMatchStartTime - this.secondMatchStartTime;

            currentEndTimeBeforeAdditional = firstTimeEndingTime + totalTimeSecondMatch;

            if (currentEndTimeBeforeAdditional >= totalTimeGame) {
              currentEndTimeBeforeAdditional = totalTimeGame;
            }
          }
        }


        // if (useCorrectMarkup == true) {
        //   firstTimeEndingTime = currentRealGameTime;
        // } else{

        // }
      }

      //aditional time second time
      if (
        currentTimeReal >= this.additionalSecondMatchStartTime &&
        this.additionalSecondMatchStartTime > 0 &&
        //additional first time
        //this.additionalSecondMatchStartTime >= this.additionalFirstMatchEndTime &&
        this.additionalSecondMatchStartTime >= this.additionalFirstMatchStartTime &&
        //interval
        this.additionalSecondMatchStartTime >= this.intervalMatchStartTime &&
        this.additionalSecondMatchStartTime >= this.intervalMatchEndTime &&
        //second time
        this.additionalSecondMatchStartTime >= this.secondMatchStartTime //&&
        //this.additionalSecondMatchStartTime >= this.secondMatchEndTime
      ) { 
        if (currentEndTimeBeforeAdditional == 0) {
          currentEndTimeBeforeAdditional = firstTimeEndingTime;
        }

        let extraTimeMatch = currentTimeReal - this.additionalSecondMatchStartTime;
        currentRealGameTime = this.convertToReadTime(currentEndTimeBeforeAdditional) + '+' + this.convertToReadTime(extraTimeMatch);

        if (currentTimeReal >= this.additionalSecondMatchEndTime && this.additionalSecondMatchEndTime > this.additionalSecondMatchStartTime) {
          let extraTimeMatchEnd = this.additionalSecondMatchEndTime - this.additionalSecondMatchStartTime;
          currentRealGameTime = this.convertToReadTime(currentEndTimeBeforeAdditional) + '+' + this.convertToReadTime(extraTimeMatchEnd);
        }
      }

      let existeFirstMatchTimeExists = false;

      //Second time
      if (
        currentTimeReal >= this.extraTimeFirstMatchStartTime &&
        this.extraTimeFirstMatchStartTime > 0 &&
        this.extraTimeFirstMatchStartTime >= this.additionalFirstMatchStartTime &&
        // this.secondMatchStartTime >= this.additionalFirstMatchEndTime &&
        this.extraTimeFirstMatchStartTime >= this.firstMatchStartTime
        // && this.secondMatchStartTime >= this.firstMatchEndTime
      ) {
        totalTimeGame += this.gameMatchExtraTime;
        let endExtraTimeFirst = this.secondMatchEndTime + ( currentTimeReal - this.extraTimeFirstMatchStartTime ) - ( this.secondMatchStartTime - this.firstMatchEndTime );
        currentRealGameTime = endExtraTimeFirst;
        existeFirstMatchTimeExists = true;
        
        if( currentRealGameTime >= totalTimeGame ){
          let extraTimeMatch = currentRealGameTime - totalTimeGame;
          currentRealGameTime = this.convertToReadTime(totalTimeGame) + '+' + this.convertToReadTime(extraTimeMatch);
        }
      }

      if (
        currentTimeReal >= this.extraTimeSecondMatchStartTime &&
        this.extraTimeSecondMatchStartTime > 0 &&
        this.extraTimeSecondMatchStartTime >= this.additionalFirstMatchStartTime &&
        // this.secondMatchStartTime >= this.additionalFirstMatchEndTime &&
        this.extraTimeSecondMatchStartTime >= this.firstMatchStartTime
        // && this.secondMatchStartTime >= this.firstMatchEndTime
      ) {
        totalTimeGame += this.gameMatchExtraTime;
        let endExtraTimeFirst = this.secondMatchEndTime + ( existeFirstMatchTimeExists ? this.gameMatchExtraTime : 0  ) + ( currentTimeReal - this.extraTimeSecondMatchStartTime ) - ( this.secondMatchStartTime - this.firstMatchEndTime );
        currentRealGameTime = endExtraTimeFirst;
        
        if( currentRealGameTime >= totalTimeGame ){
          let extraTimeMatch = currentRealGameTime - totalTimeGame;
          currentRealGameTime = this.convertToReadTime(totalTimeGame) + '+' + this.convertToReadTime(extraTimeMatch);
        }
      }

      //prevent total time
      currentRealGameTime = (typeof currentRealGameTime == 'string' || currentRealGameTime < totalTimeGame ? currentRealGameTime : totalTimeGame);
      returnFormattedDataMatch = (typeof currentRealGameTime == 'string' ? currentRealGameTime : this.convertToReadTime(currentRealGameTime));
    }
    

    return returnFormattedDataMatch;
  }

  /**
   * Return formatted data to game match
   */
  convertGameTimeFormat(time, additional = 0) {
    let newDateGame = "";

    if (time) newDateGame = this.convertToReadTime(time);
    if (additional && additional > 0 && additional != false)
      newDateGame += "+" + this.convertToReadTime(additional);
    return newDateGame;
  }

  /**
   * Return formatted date
   * @param {Int} time
   * @param {String} type - hours|minutes
   */
  convertToReadTime(time, type = 'minutes') {
    const getTime = this.convertSecondsToTime(time);

    var returnTime = '';
    if (type == 'hours') {
      if (getTime.hours > 0) returnTime = ('0' + getTime.hours + ':').slice(-3);
      returnTime += ('0' + getTime.minutes + ':').slice(-3);
    } else {
      if( ('0' + getTime.minutesFull + ':').length == 5 ){
        returnTime += ('0' + getTime.minutesFull + ':').slice(-4);
      } else {
        returnTime += ('0' + getTime.minutesFull + ':').slice(-3);
      }
    }
    returnTime += ('0' + getTime.seconds).slice(-2);
    return returnTime;
  }

  //return correct variable time by seconds
  convertSecondsToTime(time) {
    time = parseFloat(time);

    let hours = Math.floor(time / 3600);
    let minutesFull = Math.floor(time / 60);
    let minutes = Math.floor(minutesFull % 60);
    let seconds = Math.round(time % 60);

    //HFN
    if (seconds == 60) {
      seconds = 0;
      minutes += 1;
      minutesFull += 1;
    }

    return {
      hours: hours,
      minutes: minutes,
      minutesFull: minutesFull,
      seconds: seconds,
      secondsFull: time,
    };
  }

  /**
   * Convert time in string to seconds
   * @param {String} hour - 00:00:00
   */
  convertHourToSeconds(hour) {
    let times = hour.split(":");
    if (times.length == 2) {
      return Number(times[0]) * 60 + Number(times[1]);
    }
    return (
      Number(times[0]) * 60 * 60 + Number(times[1]) * 60 + Number(times[2])
    );
  }

  /**
   * Layout/Modify default style editor js
   */
  customizeLayoutVideojs() {
    const displayTimeControlBar = this._makeElement("div", {
      class: ["gamevideojs-control-display-time", "vjs-control"],
    });
    const currentTime = this._makeElement(
      "div",
      { class: "gamevideojs-control-display-time-current" },
      "00:00",
    );
    const totalTime = this._makeElement(
      "div",
      { class: "gamevideojs-control-display-time-total" },
      "00:00",
    );
    displayTimeControlBar.appendChild(currentTime);
    displayTimeControlBar.appendChild(totalTime);

    this.currentTimeElement = currentTime;
    this.currentTimeFinalElement = totalTime;

    //add progress bart with first element
    this.insertControlBefore(displayTimeControlBar);
    this.insertControlBefore(
      this.selectControlElement(".vjs-progress-control.vjs-control"),
    );
  }

  insertControlBefore(element, before = false) {
    if (before) {
      this.player.controlBar.el_.insertBefore(element, before);
    } else {
      this.player.controlBar.el_.insertBefore(
        element,
        this.player.controlBar.el_.firstChild,
      );
    }
  }

  selectControlElement(selector) {
    return this.player.controlBar.el_.querySelector(selector);
  }

  /**
   * Create element
   * @param {String} elementType - Tag Name
   * @param {Object} attr - List with Object and value
   * @param {String} value - Value added in element
   */
  _makeElement(elementType = "div", attr = {}, value = false) {
    const el = document.createElement(elementType);

    if (attr) {
      Object.keys(attr).forEach(function (index) {
        const newAttr = attr[index];

        if (Object.is(newAttr)) {
          let preperValue = "";
          Object.keys(newAttr).forEach(function (i) {
            const attVal = newAttr[i];
            if (index == "class") {
              el.classList.add(attVal);
            } else {
              preperValue += (preperValue ? " " : "") + attVal;
            }
          });

          //set Attribute
          if (preperValue) el.setAttribute(index, preperValue);
        } else {
          if (index == "class") {
            if (Array.isArray(newAttr)) {
              for (let index = 0; index < newAttr.length; index++) {
                const classItem = newAttr[index];
                el.classList.add(classItem);
              }
            } else {
              el.classList.add(newAttr);
            }
          } else {
            el.setAttribute(index, newAttr);
          }
        }
      });
    }

    if (value) el.innerHTML = value;

    return el;
  }

  /**
   * Returns only the additional time of the first half
   * @returns {string|null} Formatted additional time (e.g. "05:00") or null if none
   */
  getFirstHalfAdditionalTime() {


    if ( this.secondMatchStartTime ) {
      let time = this.returnFormattedDataMatch(this.secondMatchStartTime-1);
      time = time.split("+");
      time = typeof time[1] == "string" ? time[1] : "00:00";
      return time;
    }

    return "00:00";
  }

  /**
   * Returns only the additional time of the second half
   * @returns {string|null} Formatted additional time (e.g. "12:15") or null if none
   */
  getSecondHalfAdditionalTime() {
    let endTime = null;
    
    // Check additional time for second half
    if (this.extraTimeFirstMatchStartTime) {
      endTime = this.extraTimeFirstMatchStartTime - 1;
    } else {
      endTime = this.getEndTimeGame();
    }

    if (endTime) {
      let time = this.returnFormattedDataMatch(endTime);
      time = time.split("+");
      time = typeof time[1] == "string" ? time[1] : "00:00";
      return time;
    }
    return "00:00";
  }

  /**
   * Returns the time limit of the first half with added time
   * @returns {string} Formatted time (e.g. "45:00+05:00")
   */
  getFirstHalfLimit( completeTime = false ) {
    const regularTime = this.convertToReadTime(this.gameMatchTime);
    const additionalTime = this.getFirstHalfAdditionalTime();
    
    if ( completeTime ) {
      return additionalTime ? `${regularTime}+${additionalTime}` : regularTime;
    }

    return regularTime;
  }

  /**
   * Returns the time limit of the second half with added time
   * @returns {string} Formatted time (e.g. "90:00+12:15")
   */
  getSecondHalfLimit( completeTime = false ) {
    const regularTime = this.convertToReadTime(this.gameMatchTime * 2);
    const additionalTime = this.getSecondHalfAdditionalTime();
    
    if ( completeTime ) {
      return additionalTime ? `${regularTime}+${additionalTime}` : regularTime;
    }

    return regularTime;
  }

  /**
   * Returns all limits and additional times of the match
   * @returns {Object} Object with all match times in string format and seconds
   */
  getAllMatchLimits() {
    const firstHalfAdditionalTime = this.getFirstHalfAdditionalTime();
    const secondHalfAdditionalTime = this.getSecondHalfAdditionalTime();
    
    return {
      firstHalfLimit: this.getFirstHalfLimit(),
      firstHalfLimitSeconds: this.gameMatchTime,
      firstHalfAdditionalTime: firstHalfAdditionalTime,
      firstHalfAdditionalTimeSeconds: this.convertHourToSeconds(firstHalfAdditionalTime),
      secondHalfLimit: this.getSecondHalfLimit(),
      secondHalfLimitSeconds: this.gameMatchTime * 2,
      secondHalfAdditionalTime: secondHalfAdditionalTime,
      secondHalfAdditionalTimeSeconds: this.convertHourToSeconds(secondHalfAdditionalTime),
      firstHalfComplete: this.getFirstHalfLimit(true),
      secondHalfComplete: this.getSecondHalfLimit(true)
    };
  }

  /**
   * Converts a game time to seconds and formatted time, considering additional times
   * @param {string|number} gameTime - Game time in "MM:SS" format, "MM.SS" format or as decimal minutes
   * @returns {Object} Object with time in seconds and formatted time
   */
  convertGameTime(gameTime) {
    let minutes = 0;
    let seconds = 0;
    let minutesTwo = 0;
    let secondsTwo = 0;

    if (typeof gameTime === 'string' && gameTime.includes(':')) {
      let timeA = gameTime.split("+");
      let parts = timeA[0].split(':');
      let partsTwo = timeA[1] ? timeA[1].split(':') : [];

      minutes = parseInt(parts[0], 10);
      seconds = parseInt(parts[1], 10);

      if( partsTwo[0] ){
        minutesTwo = parseInt(partsTwo[0], 10);
        secondsTwo = parseInt(partsTwo[1], 10);
      }
    } 
    
    const requestedSeconds = minutes * 60 + seconds;
    const requestedExtraSeconds = minutesTwo * 60 + secondsTwo;
    let finalSeconds = requestedSeconds;
    
    const firstHalfLimit = this.gameMatchTime; // 45:00 em segundos
    const secondHalfLimit = this.gameMatchTime * 2; // 90:00 em segundos
    
    // Obter os tempos adicionais em segundos
    const firstHalfAdditionalTimeSeconds = this.convertHourToSeconds(this.getFirstHalfAdditionalTime());
    const secondHalfAdditionalTimeSeconds = this.convertHourToSeconds(this.getSecondHalfAdditionalTime());

    if( requestedSeconds > firstHalfLimit ){
      finalSeconds += firstHalfAdditionalTimeSeconds;
    }

    if( requestedSeconds > secondHalfLimit ){
      finalSeconds += secondHalfAdditionalTimeSeconds;
    }

    if( requestedSeconds == firstHalfLimit && requestedExtraSeconds <= firstHalfAdditionalTimeSeconds ){
      finalSeconds += requestedExtraSeconds;
    }

    if( requestedSeconds == secondHalfLimit && requestedExtraSeconds <= secondHalfAdditionalTimeSeconds ){
      finalSeconds += requestedExtraSeconds;
    }
    
    return {
      seconds: finalSeconds,
      formatted: this.convertToReadTime(finalSeconds)
    };
    
  }

  convertSecondsToFormattedTime(seconds, extraTime = 0) {
    return this.convertToReadTime( seconds ) + ( extraTime > 0 ? '+' + this.convertToReadTime( extraTime ) : '' );
  }

}

function gameVideojs(selectorElement, options = {}) {
  let runGameVideoJs = new gameVideoJsClass(selectorElement, options);
  return runGameVideoJs.run();
}
export default gameVideojs;
