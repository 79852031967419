document.addEventListener('turbolinks:load', () => {

  $('#key_incidents_customer_id').on('change', function() {
    customerID = $('#key_incidents_customer_id').val()

    if (customerID) {
      $('#q_season_id_eq').val('')
      window.filter_form.showSeasons(customerID);
      window.filter_form.showCompetitions(customerID);
      $('#q_fixture_id_eq').val('')
    } else {
      $('#q_season_id_eq').val('')
      $('#q_fixture_id_eq').val('')
      $('#q_competition_id_eq').find("option").remove();
      $('#q_competition_id_eq').append('<option value=""></option>');
    }
  });

  $('.key_incident_season_id_eq').on('change', function() {
    seasonID = $('.key_incident_season_id_eq').val()

    if (seasonID) {
      window.filter_form.showFixturesBySeason(seasonID);
    }
  });
  // Initialize competition dropdown for admin users
  if ($('#key_incidents_customer_id').length > 0 && $('#key_incidents_customer_id').val()) {
    let customerID = $('#key_incidents_customer_id').val();
    if (customerID) {
      window.filter_form.showCompetitions(customerID);
    }
  }

});



