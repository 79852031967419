export default class FilterForm {
  showCompetitions(customerID) {
    $.ajax({
      method: "GET",
      url: `/competitions/competitions_by_customer?customer_id=${customerID}`,
      dataType: "json",
    }).done(function(result) {
      $("#q_competition_id_eq").find("option").remove();

      $("#q_competition_id_eq").append(
        `<option value=""></option>`
      );
      $.each(result, function(_, item) {
        $("#q_competition_id_eq").append(
          `<option value="${item.id}">${item.name}</option>`
        );
      });
    }).fail(function(data){
      $("#q_competition_id_eq").find("option").remove();
    });
  }

  showLevels(customerID, meritTable) {
    if (meritTable) {
      var url = `/levels/levels_by_customer?customer_id=${customerID}&merit_table=${meritTable}`
    } else {
      var url = `/levels/levels_by_customer?customer_id=${customerID}`
    }

    $.ajax({
      method: "GET",
      url: url,
      dataType: "json",
    }).done(function(result) {
      $("#q_levels_id_eq").find("option").remove();
      $("#q_levels_id_eq").append(
        `<option value=""></option>`
      );
      $.each(result, function(_, item) {
        $("#q_levels_id_eq").append(
          `<option value="${item.id}">${item.name}</option>`
        );
      });
    }).fail(function(data){
      $("#q_levels_id_eq").find("option").remove();
    });
  }

  showSeasons(customerID) {
    $.ajax({
      method: "GET",
      url: `/seasons/seasons_by_customer?customer_id=${customerID}`,
      dataType: "json",
    }).done(function(result) {
      $("#q_season_id_eq").find("option").remove();

      if($("#q_season_id_eq").hasClass("add-empty-option")) {
        $("#q_season_id_eq").append(
          `<option value=""></option>`
        );
      }

      $.each(result, function(_, item) {
        $("#q_season_id_eq").append(
          `<option value="${item.id}">${item.name}</option>`
        );
      });
    }).fail(function(data){
      $("#q_season_id_eq").find("option").remove();
    });
  }

  showFixturesBySeason(seasonID) {
    $.ajax({
      method: 'GET',
      url: `seasons/${seasonID}/fixtures`,
      dataType: 'json',
    }).done(function(result){
      $("#q_fixture_id_eq").find("option").remove();

      $("#q_fixture_id_eq").append(
        `<option value=""></option>`
      );

      $.each(result.data, function(_, item) {
        $("#q_fixture_id_eq").append(
          `<option value="${item.id}">${item.name}</option>`
        );
      });
    }).fail(function(data) {
      $("#q_fixture_id_eq").find("option").remove();
    });
  }
}

