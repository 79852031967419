import $ from 'jquery'
import 'jquery-mask-plugin'

document.addEventListener('turbolinks:load', () => {
  $('#club_feedback_observation_minute_add').mask('00:00+00:00', {
    placeholder: 'MM:SS+MM:SS',
    clearIfNotMatch: true,
    translation: {
      'M': {
        pattern: /[0-9]/,
        optional: false
      },
      'm': {
        pattern: /[0-9]/,
        optional: false
      },
      'S': {
        pattern: /[0-5]/,
        optional: false
      },
      's': {
        pattern: /[0-9]/,
        optional: false
      }
    },
    optional: ['+', '0', '0', ':', '0', '0']
  })

  const convertTimeToFloat = (timeStr) => {
    if (!timeStr) return null

    if (!timeStr.includes('+')) {
      if (!timeStr.includes(':')) {
        const minutes = parseInt(timeStr)
        return isNaN(minutes) ? null : minutes
      }
      const [minutes, seconds] = timeStr.split(':').map(num => parseInt(num))
      if (isNaN(minutes)) return null
      if (isNaN(seconds)) return minutes
      return minutes + (seconds / 60)
    }

    const [baseTime, additionalTime] = timeStr.split('+')
    const [baseMinutes, baseSeconds] = baseTime.split(':').map(num => parseInt(num))
    const [addMinutes, addSeconds] = additionalTime.split(':').map(num => parseInt(num))
    
    if (isNaN(baseMinutes) || isNaN(baseSeconds)) return null
    if (isNaN(addMinutes) || isNaN(addSeconds)) {
      return baseMinutes + (baseSeconds / 60)
    }
    
    return (baseMinutes + (baseSeconds / 60)) + '&extra_time=' + (addMinutes + (addSeconds / 60))
  }

  const updateAddButtonUrl = (floatValue = null) => {
    const addButton = $('#add-observation-btn')
    let baseUrl = addButton.attr('href').split('?')[0]
    const clubId = addButton.attr('href').match(/club_id=(\d+)/)?.[1]
    
    let params = []
    if (clubId) {
      params.push(`club_id=${clubId}`)
    }
    if (floatValue !== null) {
      params.push(`event_time=${floatValue}`)
    }

    const queryString = params.length > 0 ? `?${params.join('&')}` : ''
    addButton.attr('href', `${baseUrl}${queryString}`)
  }

  $('#club_feedback_observation_minute_add').on('input', function() {
    const value = $(this).val()
    
    if (value) {
      const floatValue = convertTimeToFloat(value)
      
      if (floatValue !== null) {
        updateAddButtonUrl(floatValue)
      }
    } else {
      updateAddButtonUrl()
    }
  })
}) 